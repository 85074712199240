var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"footer"}},[_c('div',{staticClass:"nav-links-container"},[_c('div',{staticClass:"footer-links"},[_c('router-link',{attrs:{"to":"/terms-of-service"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-link',{attrs:{"navigate":navigate,"href":href,"active":isActive,"withLine":false}},[_vm._v(" Terms of Service ")])]}}])})],1),_c('div',{staticClass:"footer-links"},[_c('router-link',{attrs:{"to":"/privacy-policy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-link',{attrs:{"navigate":navigate,"href":href,"active":isActive,"withLine":false}},[_vm._v(" Privacy Policy ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }